/*------------------------------------
  File for your custom SCSS style
------------------------------------*/

/** {
  cursor: url(../svg-src/cursor-24.png) 4 12, auto;
  cursor: url(../svg-src/cursor-24.png) 2 2, pointer;
}*/

.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical
  .nav-tabs
  .navbar-vertical-aside-has-menu
  > .active.nav-link {
  border-left-color: $primary !important;
}

.nav-link {
  border-width: 0 0 0 0.2rem !important;
}

.dropdown-item {
  cursor: pointer;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../svg-src/chevron-down.svg") no-repeat right 10px center;
  background-size: 10px;
}

.table thead th {
  font-weight: 500;
}

// https://codepen.io/sfi0zy/pen/WWJKqB
.custom-textarea {
  position: relative;
  overflow: hidden;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 42px;
    width: 10px;
    height: 100%;
    padding-top: 40px;
    //background: #ffffff;
    pointer-events: none;
    z-index: 1;
  }

  & > .textarea {
    display: block;
    position: relative;
    z-index: 1;
    //height: 150px;
    //min-height: 150px;
    margin-left: 58px;
    //font-size: 16px;
    line-height: 24px;
    //outline: none;
    width: calc(100% - 58px);
    border: none;
    box-shadow: none;
    //border-radius: 0 5px 5px 0;
    resize: vertical;
    white-space: pre;
    overflow: auto;
    word-wrap: normal;
    //color: #121113;
    //background: #ffffff;
  }

  & > .textarea::-webkit-scrollbar-track {
    border-radius: 0;
    //background-color: #899878;
  }

  & > .textarea::-webkit-scrollbar {
    width: 12px;
    //background-color: #ffffff;
  }

  & > .textarea::-webkit-scrollbar-thumb {
    border-radius: 0;
    //background-color: #e4e6c3;
  }

  & > .linenumbers {
    font-family: "Ubuntu Mono", monospace;
    position: absolute;
    top: 0px;
    padding-top: 10px;
    background-color: #f9fafc;
    padding-right: 10px;
    left: 0;
    width: 58px;
    text-align: right;
    //background: #899878;
  }

  & > .linenumbers > .number {
    //font-size: 16px;
    line-height: 24px;
    padding-left: 6px;
    color: #222725;
  }
}

#react-select-2-listbox {
  z-index: 99999;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border: 0.0625rem solid #e7eaf3 !important;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: #f8fafd;
  border: 0.0625rem solid #e7eaf3 !important;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: 0 !important;
}

.ql-variable {
  color: $primary; /* Cambia esto al estilo que desees */
  background-color: $light;
  padding: 5px;
  border-radius: 0.25rem;
  font-weight: 800;
}

.ql-editor {
  line-height: 2.4 !important; /* Ajusta este valor según tus necesidades */
  font-size: 16px !important;
}

/*
   * Switch
   * @see https://www.w3schools.com/howto/howto_css_switch.asp
   */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 29px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e7eaf3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.css-16xfy0z-control {
  background-color: #f8fafd !important;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked {
  background-color: $primary;
}
form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}
.form-check-input[type="checkbox"] {
  margin-top: 3px;
  border-radius: 0.25em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input,
.form-check-label,
label.form-control {
  cursor: pointer;
}

.form-check-input {
  width: 1.1em;
  height: 1.1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(12, 18, 33, 0.3);
  appearance: none;
  print-color-adjust: exact;
}

.row {
  display: flex !important;
}

.pdf-container {
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  transition: all 0.5s ease-in-out;
}

.disclaimer-card {
  width: 50%;
  // on mobile with 100% width
  @include media-breakpoint-down(sm) {
    width: 85%;
  }
}

.live-chat-ai-button {
  margin-bottom: 50px;
}

#rating_bar {
  width: 100px;
  height: 100px;
  display: inline-block;
  display: inline;
}
#rating_bar > span:before {
  color: #c7c5c5;
  cursor: pointer;
  font-size: 3em;
}
#rating_bar:hover > span:before {
  color: #4bce32;
}

#rating_bar > span:hover ~ span:before {
  color: #c7c5c5;
}

.countdown-item {
  display: inline-block;
  margin: 0 8px;
  text-align: center;
  background-color: #fff;
  color: #000;
  width: 45px;
  height: 35px;
  padding-top: 6px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 800;
}

.countdown-column {
  margin-right: 25px;
  margin-left: 25px;
}

.back-button,
.email-item,
.category-item {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    background-color: #f8fafd;
  }
}

/* REACT BOOTSTRAP CAROUSEL */

.carousel-item-next {
  transition: transform 0s ease-in-out;
}

.carousel-item-prev {
  transition: transform 0s ease-in-out;
}
.carousel-item-left {
  transform: translateX(-100%);
}
.carousel-item-right {
  transform: translateX(100%);
}

/* PRODUCT PAGE */

.product-column-divider {
  &::before {
    border-left: 0 !important;
  }
}

.product-page {
  .link,
  p,
  li {
    font-size: 1rem !important;
  }
}

.link {
  font-weight: 600;
}

.link-secondary {
  color: #71869d;
}

.divider-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8c98a4;
}

.divider-center::before {
  margin-right: 1.5rem;
}

.divider-center::after {
  margin-left: 1.5rem;
}

.divider-center::after,
.divider-center::before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
  margin-top: 0.0625rem;
  content: "";
}

.dropdown-menu {
  // transform: translateY(-0px) !important;
}

.dropdown-item {
  border-radius: $border-radius;
}

.slick-next:before,
.slick-prev:before {
  color: $gray-400;
}

.register-features {
  max-width: 23rem;
}

// Media query para pantallas de ordenador (md y mayores)
@include media-breakpoint-up(md) {
  .register-features {
    max-width: 28rem;
  }
}

.micro:before,
.micro:after {
  position: absolute;
  content: "";
  top: -1px;
  right: 0px;
  bottom: 0;
  left: 0;
  border: solid 2px $primary;
  border-radius: 999px;
}

.micro:before {
  animation: ripple 2s linear infinite;
}

.micro:after {
  animation: ripple 2s 1s linear infinite;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

.step .step-item {
  flex: 1 1 !important;
}

@media (min-width: 768px) {
  .step-md .step-icon::after {
    left: 4rem;
  }
  .step-md:not(.step-inline) .step-content-wrapper {
    display: flex;
    flex-direction: row;
  }
}

.border-primary {
  border: 2px solid $primary;
}

.loader {
  position: relative;
  display: flex;
}
.loader span {
  position: relative;
  width: 250px;
  height: 250px;
  background: #eaeef0;
  border: 6px solid #eaeef0;
  border-radius: 50%;
  box-shadow: -8px -8px 15px rgba(255, 255, 255, 1), 8px 8px 25px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.loader span::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  box-shadow: inset 10px 10px 20px rgba(0, 0, 0, 0.5), inset -5px -5px 15px rgba(255, 255, 255, 1);
}
.loader span::after {
  content: "";
  position: absolute;
  inset: 40px;
  background: #eaeef0;
  border: 3px solid #eaeef0;
  border-radius: 50%;
  box-shadow: inset -8px -8px 25px rgba(255, 255, 255, 1), 8px 8px 25px rgba(0, 0, 0, 0.25), inset 3px 3px 10px rgba(0, 0, 0, 0.15),
    inset -1px -1px 15px rgba(255, 255, 255, 1);
}
.loader span i {
  position: absolute;
  inset: 0;
  border-radius: 50%;
  filter: blur(5px);
  background: linear-gradient(#3fddff, #ae94ff, #5c70e0);
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rocket {
  position: absolute;
  inset: 50px;
  z-index: 10;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.rocket .fa-rocket {
  position: absolute;
  color: $primary;
  font-size: 3.5em;
  width: 5rem;
  -webkit-text-stroke: 2px #000;
  animation: animateRocket 0.2s linear infinite;
}
@keyframes animateRocket {
  0%,
  100% {
    transform: translate(0, 0) rotate(-45deg);
  }
  50% {
    transform: translate(0, 3px) rotate(-45deg);
  }
}
.fa-cloud {
  position: absolute;
  top: calc(35px * var(--i));
  left: calc(45px * var(--i));
  font-size: 3em;
  color: #fff;
  -webkit-text-stroke: 2px #000;
  animation: animateClouds 1s linear infinite;
  animation-delay: calc(-0.5s * var(--i));
}
@keyframes animateClouds {
  0% {
    transform: translateY(calc(-35 * 5px));
  }
  100% {
    transform: translateY(calc(35 * 5px));
  }
}

.cloud-0 {
  --i: 0;
}

.cloud-1 {
  --i: 1;
}

.cloud-2 {
  --i: 2;
}

.cloud-3 {
  --i: 3;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.reveal-item {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-name: fadeInUp;
  animation-duration: 1s; /* Asegúrate de tener un tiempo de duración */
}

.form-control-custom {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.3125rem) !important;
  // padding: 0.59375rem 0.95rem !important;
  margin-left: 15px !important;
  font-size: 0.95rem !important;
  font-weight: 400 !important;
  line-height: 1.6 !important;
  color: #1e2022 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 0.0625rem solid #e7eaf3 !important;
  border-radius: 0.3125rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.flag {
  border-radius: 2px;
  border: none;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: unset !important;
}

.project {
  &:hover {
    color: $primary;
  }
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #1e2022;
}

.btn-gradient {
  color: white;
  background: -webkit-linear-gradient(225deg, rgb(251, 175, 21), rgb(251, 21, 242), rgb(21, 198, 251)) 0% 0% / 300% 300%;
  background-size: 200% auto;
  -webkit-animation: gradient_move 3s ease infinite;
  animation: gradient_move 3s ease infinite;
  border: none;
  &:hover {
    color: white;
  }
}

.btn-gradient-black {
  color: white;
  background: -webkit-linear-gradient(225deg, #000000, #1a1a1a, #333333) 0% 0% / 300% 300%;
  background-size: 200% auto;
  -webkit-animation: gradient_move 2s ease infinite;
  animation: gradient_move 2s ease infinite;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
  &:hover {
    color: #efefef;
  }
}

.progress-gradient {
  background: -webkit-linear-gradient(225deg, rgb(251, 175, 21), rgb(251, 21, 242), rgb(21, 198, 251)) 0% 0% / 300% 300%;
  background-size: 200% auto;
  -webkit-animation: gradient_move 3s ease infinite;
  animation: gradient_move 3s ease infinite;
}

@-webkit-keyframes gradient_move {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

@keyframes gradient_move {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

$size: 100px;

@keyframes rotate {
  0% {
    transform: rotateX(-37.5deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
  100% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

.cube,
.cube * {
  position: absolute;
  width: $size + 1;
  height: $size + 1;
}

.sides {
  animation: rotate 3s ease infinite;
  animation-delay: 0.8s;
  transform-style: preserve-3d;
  transform: rotateX(-37.5deg) rotateY(45deg);
}

.cube .sides * {
  box-sizing: border-box;
  background-color: rgba($primary, 0.5);
  border: $size / 20 solid $dark;
}

@mixin side($name, $rotate, $i) {
  .#{$name} {
    animation: #{$name}-animation 3s ease infinite;
    animation-delay: $i * 100ms;
    transform: #{$rotate} translateZ($size);
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }

  @keyframes #{$name}-animation {
    0% {
      opacity: 1;
      transform: #{$rotate} translateZ($size);
    }
    20% {
      opacity: 1;
      transform: #{$rotate} translateZ($size / 2);
    }
    70% {
      opacity: 1;
      transform: #{$rotate} translateZ($size / 2);
    }
    90% {
      opacity: 1;
      transform: #{$rotate} translateZ($size);
    }
    100% {
      opacity: 1;
      transform: #{$rotate} translateZ($size);
    }
  }
}

.cube .sides {
  @include side("top", rotateX(90deg), 0);
  @include side("bottom", rotateX(-90deg), 0);
  @include side("front", rotateY(0deg), 1);
  @include side("back", rotateY(-180deg), 1);
  @include side("left", rotateY(-90deg), 1);
  @include side("right", rotateY(90deg), 1);
}

.video-player-icon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 4.25rem;
  height: 4.25rem;
  font-size: 1.25rem;
  border-radius: 50%;
  color: #1e2022;
  background-color: #fff;
  backface-visibility: hidden;
  transform: perspective(1px) translateZ(0);
  transition: 0.3s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    // scale can not be because it moves the element
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.img-dark-overlay {
  position: relative;
}

.img-dark-overlay::before {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Overlay inicial */
  border-radius: 0.3125rem !important; /* Mantiene los bordes redondeados */
  transition: background-color 0.3s ease; /* Transición suave */
  border-radius: inherit; /* Mantiene los bordes redondeados */
}

.img-dark-overlay img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: inherit;
  position: relative;
}

.img-dark-overlay:hover::before {
  background-color: rgba(0, 0, 0, 0.7); /* Overlay más oscuro al pasar el mouse */
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: var(--bs-tooltip-bg);
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  right: -1px;
}

.tooltip .tooltip-arrow:before {
  border-color: #0000;
  border-style: solid;
  content: "";
  position: absolute;
}

.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  margin-left: 0;
}

.dropdown-menu {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.form-check-input:checked {
  border: none;
}

.colored-text {
  position: relative;
  background: linear-gradient(90deg, #5a43d6 -3.76%, #ea61e6 100%);
  background-clip: text;
  color: transparent;
}
